<template>
  <div>
    <a-modal
      title="确认理事工作"
      v-model="isShow"
      ok-text="发送"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <div>请确认以下理事工作：</div>
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="理事人">
          <a-select
            v-decorator="['user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                {
                  required: true,
                  message: '请选择理事人'
                }
              ]
            }]"
            placeholder="请选择理事人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="理事日期">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            v-decorator="[
              'director_at',
              {
                rules: [{ required: true, message: '请选择理事日期' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { findTenantTaskUserOptions, findUser } from '@/api/user'
import { findOrderInfo, confirmDirector } from '@/api/order'
import { formatDateTimeMin } from '@/utils/time'

export default {
  name: 'Confirm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirm_items' }),
      submitting: false,
      phone: '',
      hall: '',
      deadName: '',
      deadDate: '',
      age: '',
      sex: '',
      order: {},
      userOptions: [],
      loadingUserOptions: false,
      directorOptions: [],
      defaultUser: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findOrderInfo(this.id).then((res) => {
        this.order = res.data
        this.$nextTick(() => {
          var startDate = this.$moment(this.$moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')
          this.form.setFieldsValue({ director_at: this.$moment(startDate, 'YYYY-MM-DD HH:mm'), notes: res.data.precaution_content })
        })
      })
    },
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'confirm_director', order_id: this.serviceOrderId, order_type: 'service' }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
        }
        this.loadingUserOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const fieldsValue = this.form.getFieldsValue()
          const values = {
            ...fieldsValue,
            director_date: formatDateTimeMin(fieldsValue.director_at)
          }
          confirmDirector(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }

}
</script>
<style lang="less" scoped>
.attention-title {
  color: red;
}

</style>
