var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":"确认理事工作","ok-text":"发送","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } }},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',[_vm._v("请确认以下理事工作：")]),_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"理事人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
            initialValue: _vm.defaultUser.id > 0 ? _vm.defaultUser.id : undefined,
            rules: [
              {
                required: true,
                message: '请选择理事人'
              }
            ]
          }]),expression:"['user_id', {\n            initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,\n            rules: [\n              {\n                required: true,\n                message: '请选择理事人'\n              }\n            ]\n          }]"}],attrs:{"placeholder":"请选择理事人"},on:{"load":_vm.loadingUserOptions,"change":_vm.handlePhoneNumberChange}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            initialValue: _vm.defaultUser.phone_number,
            normalize: this.$lodash.trim
          }]),expression:"['phone_number', {\n            initialValue: defaultUser.phone_number,\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"理事日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'director_at',
            {
              rules: [{ required: true, message: '请选择理事日期' }],
            } ]),expression:"[\n            'director_at',\n            {\n              rules: [{ required: true, message: '请选择理事日期' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }